export const breakpoints = {
  sm: '640px',
  md: '960px',
  lg: '1120px',
  xl: '1512px',
} as const;

type BreakpointKeys = keyof typeof breakpoints;

type BreakPointRecord = Record<
  BreakpointKeys | `${BreakpointKeys}Query`,
  string
>;

export const screen = Object.entries(breakpoints).reduce(
  (acc, [breakpoint, value]) => {
    const str = `(min-width: ${value})`;

    return {
      ...acc,
      [`${breakpoint}Query`]: str,
      [`${breakpoint}`]: `@media${str}`,
    };
  },
  {} as BreakPointRecord,
);

import { css } from '@emotion/react';

import { screen } from '@/components/common/breakpoints';

// Misc css variables that don't fit anywhere else
export const misc = css`
  :root {
    --opacity-disabled: 64%;
    --desktop-max-width: 1440px;
    --desktop-max-content-width: 1312px;
    --navigation_ex-desktop-max-width: 1280px;
    --mobile-min-width: 320px;
    --shadow-base: 0px 4px 54px 0px rgb(0, 0, 0, 10%);
    --shadow-subtle: 0px 6px 112px 0px rgb(0, 0, 0, 5%);

    --banner-height: var(--spacing-700);
    --navbar-height: 56px;

    --section-radius: var(--radius-300);

    --full-navbar-height: calc(
      var(--navbar-height) + var(--spacing-medium) + 1px
    );

    ${screen.md} {
      --banner-height: 64px;
      --navbar-height: 85px;
      --navbar-top: var(--spacing-x-large);

      --full-navbar-height: calc(
        var(--navbar-height) + var(--navbar-top) + 1px
      );
      --navbar_ex-padding-top: 40px;

      --section-radius: var(--radius-450);
    }
  }
`;

import createImageUrlBuilder from '@sanity/image-url';
import { createClient } from 'next-sanity';

import type { SanityImageSource } from '@sanity/image-url/lib/types/types';
import type { SanityClient } from 'next-sanity';

// TODO: can be moved into a seperate file (e.g. sanity.env.ts)

export const readToken = process.env.SANITY_API_READ_TOKEN || '';
export const dataset = process.env.STORYBOOK
  ? 'staging'
  : process.env.NEXT_PUBLIC_SANITY_DATASET || 'development';
export const projectId = process.env.STORYBOOK
  ? 'dlb8d5f6'
  : process.env.NEXT_PUBLIC_SANITY_PROJECT_ID;
export const apiVersion =
  process.env.NEXT_PUBLIC_SANITY_API_VERSION || '2023-06-21';

/**
 * Set up a helper function for generating Image URLs with only the asset reference data in your documents.
 * Read more: https://www.sanity.io/docs/image-url
 * */
export const getSanityImage = (source: SanityImageSource) =>
  createImageUrlBuilder({ projectId, dataset }).image(source);

export const getSanityImageUrl = (source: SanityImageSource) => {
  const image = getSanityImage(source);

  try {
    return image.url();
  } catch (error) {
    console.warn(error);
    return '';
  }
};

interface ClientOptions {
  useCdn?: boolean;
}

export function getClient(
  preview?: { token: string },
  options?: ClientOptions,
): SanityClient {
  const { useCdn = false } = options ?? {};

  const client = createClient({
    projectId,
    dataset,
    apiVersion,
    useCdn,
    perspective: 'published',
  });

  if (preview) {
    if (!preview.token) {
      throw new Error('You must provide a token to preview drafts');
    }
    return client.withConfig({
      token: preview.token,
      useCdn: false,
      ignoreBrowserTokenWarning: true,
      perspective: 'previewDrafts',
    });
  }
  return client;
}

export const blocksToText = (blocks, opts = {}) => {
  const options = { nonTextBehavior: 'remove', ...opts };

  if (!blocks) {
    return '';
  }

  return blocks
    .map((block) => {
      if (block._type !== 'block' || !block.children) {
        return options.nonTextBehavior === 'remove'
          ? ''
          : `[${block._type} block]`;
      }

      return block.children.map((child) => child.text).join('');
    })
    .join('\n\n');
};

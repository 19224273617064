import { sendGTMEvent } from '@next/third-parties/google';

import type { SanityAudienceType } from '@/types/sanity';
import type { Locale, Navigation_exType } from '@/types/shared';

declare global {
  interface Window {
    DY: any;
    Intercom: any;
    updateConsentStatus: any;
  }
}

export const groupBy = function groupBy<T>(
  arrayToGroup: T[],
  groupOnKey: string,
) {
  return arrayToGroup.reduce((acc, item) => {
    (acc[item[groupOnKey]] = acc[item[groupOnKey]] || []).push(item);
    return acc;
  }, {});
};

export const getSEO = (page) => {
  return {
    meta_title: page.data.meta_title,
    meta_description: page.data.meta_description,
  };
};

export const generateSlug = (value: string) => {
  // 1) convert to lowercase
  // 2) remove dashes and pluses
  // 3) remove everything but alphanumeric characters and dashes
  // 4) replace spaces with dashes

  return value
    .toLowerCase()
    .replace(/-+/g, '')
    .replace(/[^a-z0-9-]/g, '')
    .replace(/\s+/g, '-');
};

export const GTMPageView = (url: string) => {
  interface PageEventProps {
    event: string;
    page: string;
  }

  const pageEvent: PageEventProps = {
    event: 'pageview',
    page: url,
  };

  sendGTMEvent(pageEvent);
  return pageEvent;
};

export const isValidLocale = (locale: string) => {
  const validLocales = ['en-GB', 'en-US'];

  return validLocales.includes(locale);
};

export const getValidLocale = (locale: string) => {
  if (isValidLocale(locale)) {
    return locale as Locale;
  }

  return null;
};

export const getCookie = (name: string) => {
  const nameEquals = `${name}=`;
  const foundCookie = document.cookie
    ?.split('; ')
    .find((c) => c.indexOf(nameEquals) === 0);
  if (foundCookie) {
    return decodeURIComponent(foundCookie.substring(nameEquals.length));
  }
  return null;
};

interface utmQuery {
  [key: string]: string | string[];
}

export const addCookieForUTM = (queries: utmQuery): void => {
  const utmCookie = getCookie('utmParams');
  if (!utmCookie) {
    const concatUTMParams = Object.entries(queries)
      .filter(([key]) => key.startsWith('utm'))
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    const dateExpires = new Date();
    dateExpires.setTime(dateExpires.getTime() + 90 * 24 * 60 * 60 * 1000);
    document.cookie = `utmParams=${concatUTMParams}; expires=${dateExpires.toUTCString()}; Secure; path=/`;
  }
};

export const addUTMToPlatformLinks = () => {
  const utmCookie = getCookie('utmParams');
  if (utmCookie) {
    const platformLinks: HTMLAnchorElement[] = Array.from(
      document.querySelectorAll("a[href*='https://platform.multiverse.io']"),
    );
    platformLinks.forEach((link) => {
      const paramSeperator = link.search ? '&' : '?';
      // eslint-disable-next-line no-param-reassign
      link.href = `${link}${paramSeperator}${utmCookie}`;
    });
  }
};

export const getChildrenText = (props): string => {
  return props && props.children
    ? props.children
        .map((node) => (typeof node === 'string' ? node : node.text || ''))
        .join('')
    : '';
};

export const getHeadingId = (props): string => {
  const childrenText = getChildrenText(props);

  return childrenText
    ? encodeURIComponent(childrenText.replaceAll(' ', '-').toLowerCase())
    : '';
};

export const getCurrentBreakpoint = (breakpoints, windowWidth) => {
  const breakpointKeys = Object.keys(breakpoints);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < breakpointKeys.length; i++) {
    const breakpoint = breakpointKeys[i];
    if (windowWidth < parseInt(breakpoints[breakpoint], 10)) {
      return breakpoint;
    }
  }

  return 'max'; // If the window width is larger than all breakpoints
};

export const coloursByTheme = {
  default: {
    primary: 'ultraviolet',
    secondary: 'ultraviolet-tint',
  },
  employers: {
    primary: 'electron',
    secondary: 'electron-tint',
  },
  upskillers: {
    primary: 'supernova',
    secondary: 'supernova-tint',
  },
  careerStarters: {
    primary: 'aurora',
    secondary: 'aurora-tint',
  },
  reskillers: {
    primary: 'molten',
    secondary: 'molten-tint',
  },
};

export const getRandomFromArray = <T>(
  array: T[],
  numValuesToReturn: number = 1,
): T | T[] | undefined => {
  if (!Array.isArray(array) || array.length === 0) {
    return undefined;
  }

  if (numValuesToReturn <= 0) {
    return [];
  }

  if (numValuesToReturn >= array.length) {
    return [...array];
  }

  const randomValues = new Set<T>();
  while (randomValues.size < numValuesToReturn) {
    const randomIndex = Math.floor(Math.random() * array.length);
    randomValues.add(array[randomIndex]);
  }

  return numValuesToReturn === 1 ? [...randomValues][0] : [...randomValues];
};

export const blockUrlWithAudience = (
  url: string,
  urlAudience: SanityAudienceType,
  pageAudience: SanityAudienceType,
  noAudienceToggle: boolean,
  isAudienceSwitcherEnabled: boolean,
) => {
  if (noAudienceToggle || !url || !isAudienceSwitcherEnabled) return url;

  let urlPreAudience = '';
  let urlPostAudience = url;
  const splittedUrl = url.split('/');

  const localeIndex = splittedUrl.findIndex(
    (part) => part === 'en-GB' || part === 'en-US',
  );

  if (localeIndex !== -1) {
    urlPreAudience = splittedUrl.slice(0, localeIndex + 1).join('/');
    urlPostAudience = splittedUrl
      .slice(localeIndex + 1, splittedUrl.length)
      .join('/');
  }

  if (!urlPostAudience.startsWith('/') && !urlPostAudience.startsWith('http')) {
    urlPostAudience = `/${urlPostAudience}`;
  }

  let audiencePath = '';

  if (urlAudience) audiencePath = urlAudience.basePath;
  else if (pageAudience) audiencePath = pageAudience.basePath;

  return `${urlPreAudience}${audiencePath}${urlPostAudience}`;
};

export const queryParametersWithAudience = (
  slug: string | string[],
  navigations_ex: Navigation_exType[],
) => {
  slug = typeof slug === 'string' ? slug : (slug as string[]).join('/');

  let queryParams: { slug: string; audienceRef?: string } = { slug };

  navigations_ex.forEach((nav) => {
    if (typeof slug === 'string' && slug.includes(nav.audience?.basePath)) {
      const splitSlug = slug.split('/');

      slug = splitSlug.slice(0, splitSlug.length - 1).join('/');

      const audienceRef = nav.audience._id;

      queryParams = {
        slug,
        audienceRef,
      };
    }
  });

  if (
    (slug.startsWith('programmes') || slug.startsWith('programs')) &&
    queryParams.slug.split('/').length !== 1
  ) {
    const splitSlug = queryParams.slug.split('/');
    queryParams.slug = splitSlug.slice(1, splitSlug.length).join('/');
  }

  return queryParams;
};

import { css } from '@emotion/react';
import emotionReset from 'emotion-reset';

export const cssReset = css`
  ${emotionReset}

  *, *::after, *::before {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
  }

  body {
    font-feature-settings: var(--font-feature-settings);
    font-family: var(--font-family-saans);
    background-color: var(--colour-white);
    color: var(--text-strong);
  }
`;
